import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Contributing to the website'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contributing to the website`}</h1>
    <blockquote>
      <p parentName="blockquote">{`The website application is still under development.`}</p>
    </blockquote>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://arwes.dev"
      }}>{`website`}</a>{` is a `}<a parentName="p" {...{
        "href": "https://gatsbyjs.com"
      }}>{`Gatsby`}</a>{` web
application written in JavaScript.`}</p>
    <h2>{`Setting up`}</h2>
    <p>{`The repository has to be set up first. See `}<a parentName="p" {...{
        "href": "/project/contributing/workflow"
      }}>{`workflow instructions`}</a>{`.`}</p>
    <p>{`Then the website can be installed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# at root folder
cd website
npm install
`}</code></pre>
    <h2>{`Running`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# at root folder
cd website
npm run develop
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      